import { FormikValues } from 'formik';
import React from 'react';

interface LocalStorageState {
    key: string;
    value: unknown;
}

export const useLocalStorageState = ({ key, value }: LocalStorageState): [FormikValues, (values: FormikValues) => void] => {
    const parsedLocalStorage = JSON.parse(localStorage.getItem(key) || '{}');
    const initialValue = Object.keys(parsedLocalStorage).length > 0 ? parsedLocalStorage : value as FormikValues;
    const [localStorageState, setLocalStorageState] = React.useState<FormikValues>(initialValue);

    const handleUpdateLocalStorageState = React.useCallback(
        (x: FormikValues) => {
            setLocalStorageState(x);
            localStorage.setItem(key, JSON.stringify(x));
        },
        [key]
    );

    return [localStorageState, handleUpdateLocalStorageState];
};