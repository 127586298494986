import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Slide,
  Typography,
} from "@mui/material";
import { useReactMediaRecorder } from "react-media-recorder";
import { useLocation, useNavigate } from "react-router";
import { FiberManualRecord, StopCircle } from "@mui/icons-material";
import Countdown from "react-countdown";
import throttle from "lodash.throttle";

const VideoPreview = ({
  stream,
  setHeight,
}: {
  stream: MediaStream | null;
  setHeight?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, setHeight]);
  if (!stream) {
    return null;
  }
  return (
    <video
      ref={videoRef}
      style={{
        display: "block",
        transform: "scaleX(-1)",
        WebkitTransform: "scaleX(-1)",
        objectFit: "cover",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1,
      }}
      playsInline
      autoPlay
      muted
      loop
    />
  );
};

// @ts-ignore
document.fullscreenEnabled =
  document.fullscreenEnabled ||
  // @ts-ignore
  document.mozFullScreenEnabled ||
  // @ts-ignore
  document.documentElement.webkitRequestFullScreen;

async function requestFullscreen(element: HTMLElement) {
  try {
    if (element.requestFullscreen) {
      await element.requestFullscreen();
      // @ts-ignore
    } else if (element.mozRequestFullScreen) {
      // @ts-ignore
      await element.mozRequestFullScreen();
      // @ts-ignore
    } else if (element.webkitRequestFullScreen) {
      // @ts-ignore
      await element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } catch (error) {}
}

const WAIT_SECONDS = 4;

export default function Record() {
  const navigate = useNavigate();
  const location = useLocation();
  const [prepare, setPrepare] = React.useState(false);
  const [status, setStatus] = React.useState("");
  let [startTimestamp, setStartTimestamp] = React.useState(0);
  let [timestamps, setTimestamps] = React.useState<any[]>([]);
  let [deviceOrientation, setDeviceOrientation] = React.useState<any>({});
  const onStart = React.useCallback(() => {
    setStartTimestamp(Date.now());
    setTimestamps((timestamps) => {
      timestamps.push({
        timestamp: 0,
        ...deviceOrientation,
      });
      return timestamps;
    });
  }, [deviceOrientation, setTimestamps]);
  const onStop = React.useCallback(
    (mediaBlobUrl: string, blob: Blob) => {
      navigate("/player", {
        state: {
          mediaBlobUrl,
          blob,
          entry: {
            ...(location.state as any),
            timestamps,
          },
        },
      });
    },
    [navigate, location.state, deviceOrientation, timestamps]
  );
  const deviceOrientationListener = React.useCallback(
    throttle(({ absolute, alpha, beta, gamma }: DeviceOrientationEvent) => {
      setDeviceOrientation((deviceOrientation: any) =>
        Object.assign(deviceOrientation, { absolute, alpha, beta, gamma })
      );
      console.log({ status, absolute, alpha, beta, gamma });
      if (status === "recording" && startTimestamp) {
        setTimestamps((timestamps) => {
          timestamps.push({
            timestamp: startTimestamp ? Date.now() - startTimestamp : 0,
            absolute,
            alpha,
            beta,
            gamma,
          });
          return timestamps;
        });
      }
    }, 250),
    [status, startTimestamp, setTimestamps, setDeviceOrientation]
  );
  React.useEffect(() => {
    if (!(location.state as any)) navigate("/form");
    else if (document.fullscreenEnabled) {
      requestFullscreen(document.documentElement);
    }
    if (
      DeviceOrientationEvent &&
      typeof (DeviceOrientationEvent as any).requestPermission === "function"
    ) {
      (DeviceOrientationEvent as any).requestPermission();
    }
  }, []);
  React.useEffect(() => {
    window.addEventListener("deviceorientation", deviceOrientationListener);
    return () => {
      window.removeEventListener(
        "deviceorientation",
        deviceOrientationListener
      );
    };
  }, [deviceOrientationListener]);
  const {
    status: _status,
    startRecording,
    stopRecording,
    previewStream,
  } = useReactMediaRecorder({
    video: true,
    askPermissionOnMount: true,
    onStart,
    onStop,
  });
  React.useEffect(() => {
    setStatus(_status);
  }, [_status]);
  const start = React.useCallback(() => {
    setPrepare(true);
    setTimeout(() => {
      startRecording();
      setPrepare(false);
    }, 1000 * WAIT_SECONDS);
  }, []);
  return (
    <Slide direction="right" in appear mountOnEnter unmountOnExit>
      <Box sx={{ my: 4 }}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "end",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <CardMedia
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "100%",
            }}
          >
            <VideoPreview stream={previewStream} />
          </CardMedia>
          {prepare ? (
            <CardContent
              style={{
                zIndex: 1,
                backgroundColor: "rgba(0,0,0,0.3)",
                position: "absolute",
                width: "100vw",
                height: "100vh",
                top: 0,
                left: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
              }}
            >
              <Typography variant="h1" component="div">
                <Countdown
                  date={Date.now() + 1000 * WAIT_SECONDS}
                  renderer={({ seconds }) => seconds}
                />
              </Typography>
            </CardContent>
          ) : (
            <CardActions sx={{ zIndex: 1 }}>
              {status == "idle" && (
                <Button startIcon={<FiberManualRecord />} onClick={start}>
                  Start Recording
                </Button>
              )}
              {status == "recording" && (
                <Button startIcon={<StopCircle />} onClick={stopRecording}>
                  Stop Recording
                </Button>
              )}
            </CardActions>
          )}
        </Card>
      </Box>
    </Slide>
  );
}
