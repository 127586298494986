import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Route, Routes } from "react-router";
import GettingStarted from "./pages/GettingStarted";
import Form from "./pages/Form";
import Record from "./pages/Record";
import Player from "./pages/Player";
import { Grid } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.getrealift.com/">
        Realift, LLC
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 4, minHeight: "100vh" }}
      >
        <img
          src="./RL_logo_dark.svg"
          alt="Logo"
          width="150px"
          height="50px"
          style={{ margin: "0 auto", display: "block", zIndex: 5 }}
        />
        <Routes>
          <Route path="/" element={<GettingStarted />} />
          <Route path="/form" element={<Form />} />
          <Route path="/record" element={<Record />} />
          <Route path="/player" element={<Player />} />
        </Routes>
        <Copyright />
      </Grid>
    </Container>
  );
}
