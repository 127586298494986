import * as React from "react";
import { Box, Button, Grid, Slide, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { Replay, Send } from "@mui/icons-material";
import { Storage } from "aws-amplify";
import { Entry, EntryMetaData } from "../models";
import { DataStore } from "aws-amplify";
import { ModelInit } from "@aws-amplify/datastore";

export default function Player() {
  const location = useLocation();
  const navigate = useNavigate();
  const [uploading, setUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    if (!(location.state as any)?.mediaBlobUrl) navigate("/form");
    console.log('location.state', location.state)
  }, []);

  const submit = React.useCallback(() => {
    const { mediaBlobUrl, blob, entry } = location.state as any;
    console.log({ mediaBlobUrl, blob, entry });
    setUploading(true);
    DataStore.save(new Entry(entry as ModelInit<Entry, EntryMetaData>))
      .then((entry) => {
        Storage.put(entry.id, blob, {
          contentType: blob.type,
          progressCallback(progress) {
            setProgress((progress.loaded / progress.total) * 100);
          },
        }).then(() => {
          setSuccess(true);
        });
      })
      .catch((error) => {
        console.log("Error saving post", error);
      });
  }, []);

  return (
    <Slide direction="right" in appear mountOnEnter unmountOnExit>
      <div>
        <video
          src={(location.state as any)?.mediaBlobUrl}
          style={{
            display: "block",
            transform: "scaleX(-1)",
            WebkitTransform: "scaleX(-1)",
            objectFit: "cover",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
          controls
          playsInline
          autoPlay
          muted
          loop
        />
        <Box
          sx={{
            p: 3,
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}
            sx={{ height: "100%" }}
          >
            {uploading && (
              <Grid item>
                <Typography variant="h1" component="div">
                  {progress.toFixed(2)}%
                </Typography>
              </Grid>
            )}
            {success ? (
              <Grid item>
                <Button startIcon={<Replay />} onClick={() => navigate(-1)}>
                  Record an other one
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  startIcon={<Replay />}
                  sx={{ backgroundColor: "#1b1464!important", mr: 2 }}
                  onClick={() => navigate(-1)}
                  disabled={uploading}
                >
                  Redo
                </Button>
                <Button
                  endIcon={<Send />}
                  onClick={submit}
                  disabled={uploading}
                >
                  Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    </Slide>
  );
}
