/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://gnwg5f6cgrfnhbolgddtbasrvy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-o4k3nh32vff6hj6b5ktexb3efm",
    "aws_cognito_identity_pool_id": "us-east-2:e0bc75d3-6d1d-4290-9d93-68783fc0e36d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_IH3CXJEUW",
    "aws_user_pools_web_client_id": "3gpiue35f6jciscqgddfonbhke",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "vorm5c27511aa8864ef69f4ae83621e0fae6170220-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
