import React from "react";
import {
  Grid,
  Paper,
  Slide,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Button,
  Switch,
  Stack,
  Typography,
  styled,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  Input,
  InputBase,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useLocalStorageState } from "../hooks/localStorageState";
import { useNavigate } from "react-router";

const PARTS = [
  {
    key: "height",
    label: "Height",
  },
  {
    key: "waist",
    label: "Waist",
  },
  {
    key: "bust",
    label: "Bust",
    gender: "female",
  },
  {
    key: "chest",
    label: "Chest",
    gender: "male",
  },
  {
    key: "hips",
    label: "Hips",
  },
  {
    key: "sleeve",
    label: "Sleeve",
  },
  {
    key: "upperBody",
    label: "Upper Body",
  },
  {
    key: "inseam",
    label: "Inseam",
  },
  {
    key: "neck",
    label: "Neck",
  },
];

const LOCAL_STORAGE_KEY = "vormFormValues";
const INITIAL_VALUES = {
  name: "",
  gender: "",
  isMetric: false,
  height: 0,
  waist: 0,
  bust: 0,
  chest: 0,
  hips: 0,
  sleeve: 0,
  upperBody: 0,
  inseam: 0,
  neck: 0,
  wearing: "",
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function Form() {
  const navigate = useNavigate();
  const [initialValues, handleUpdateForm] = useLocalStorageState({
    key: LOCAL_STORAGE_KEY,
    value: INITIAL_VALUES,
  });
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      navigate("/record", { state: values });
    },
  });
  useEffect(() => {
    handleUpdateForm(formik.values);
  }, [formik.values, handleUpdateForm]);
  const fields = useMemo(() => {
    return PARTS.filter(
      (part) =>
        formik.values.gender &&
        (!part.gender || part.gender == formik.values.gender)
    );
  }, [formik.values.gender]);
  return (
    <Slide direction="right" in appear mountOnEnter unmountOnExit>
      <Box component="div" sx={{ p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={3}
            columns={1}
            direction="column"
            justifyContent="center"
          >
            <Grid
              item
              xs={1}
              alignSelf="center"
              style={{ width: "100%", maxWidth: "800px" }}
            >
              <Paper
                sx={{
                  overflowY: "scroll",
                  padding: 3,
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 1, sm: 2 }}
                >
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      required
                      id="name"
                      name="name"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={
                        formik.touched.name && (formik.errors.name as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="gender" required variant="outlined">
                        Gender
                      </InputLabel>
                      <Select
                        fullWidth
                        variant="outlined"
                        label="Gender"
                        id="gender"
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Stack
                      component="label"
                      direction="row"
                      spacing={1}
                      justifyContent="end"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography>Imperial</Typography>
                      <AntSwitch
                        name="isMetric"
                        checked={formik.values.isMetric}
                        onChange={formik.handleChange}
                      />
                      <Typography>Metric</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <Stack
                      component="label"
                      direction="row"
                      spacing={1}
                      justifyContent="end"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                    >
                    <FormControl fullWidth variant="outlined">
                      <InputLabel variant="outlined">Height</InputLabel>
                      <InputBase />
                      <FormHelperText>
                        Feet
                      </FormHelperText>
                    </FormControl>
                      <FormControl fullWidth variant="outlined">
                        <InputBase />
                        <FormHelperText>
                          Inches
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </Grid>
                  {(
                    fields as {
                      key: keyof typeof formik.values;
                      label: string;
                      gender: string;
                    }[]
                  ).map((field) => (
                    <Grid key={field.key} item xs={1}>
                      <FormControl>
                        <InputLabel htmlFor={field.key.toString()}>
                          {field.label}
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          required
                          label={field.label}
                          id={field.key.toString()}
                          name={field.key.toString()}
                          value={formik.values[field.key]}
                          onChange={formik.handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              {formik.values.isMetric ? "cm" : "in"}
                            </InputAdornment>
                          }
                          error={
                            formik.touched[field.key] &&
                            Boolean(formik.errors[field.key])
                          }
                          type="number"
                        />
                        <FormHelperText>
                          {formik.touched[field.key] &&
                            (formik.errors[field.key] as string)}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={1} alignSelf="center">
              <Button endIcon={<ArrowForwardIos />} type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Slide>
  );
}
